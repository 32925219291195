import { createReducer, on } from '@ngrx/store';
import { reset, saveGHICustomPlanDetails,  } from '../actions/ghi-custom-plan-details.action';
import { ghiCustomPlanDetailsInitialState } from '../initial-state/ghi-custom-plan-details.state';



const _ghiCustomPlanDetailsReducer = createReducer(
    ghiCustomPlanDetailsInitialState,
    on(saveGHICustomPlanDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(reset, (state) => ({}))
);

export function ghiCustomPlanDetailsReducer(state = ghiCustomPlanDetailsInitialState, action) {
    return _ghiCustomPlanDetailsReducer(state, action);
}