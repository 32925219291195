import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
// Required for Touch functionality of CRUX Components
import 'hammerjs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CRUX_APP_BUILD } from '@crux/components';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { LayoutModule } from './layout';
import { WidgetsModule } from '../app/widgets';
import {
  AnalyticsModule,
  GoogleTagManagerService,
  ApplicationInsightsService,
  GoogleAnalyticsService,
  IdlingModule,
} from '@crux/services';
import { WINDOW_PROVIDERS } from './services/window.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AppContextGuard, BrowserBackGuard } from './guards';

import * as reducers from './state/reducers';
import { RouterStateService } from './services/router-state.service';
import { AppRedirectGuard } from './guards/app-redirect.guard';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { CommonDialogService } from './services/common-dialog.service';
import { MsalModule, MsalService, MsalGuard, BroadcastService } from '@azure/msal-angular';
import { MsalAuthGuard } from './guards/msal-auth.guard';

import { LoadingIndicatorComponent } from './widgets/shared/loading-indicator/loading-indicator.component';
import { LoadingIndicatorModule } from './widgets/shared/loading-indicator/loading-indicator.module';
import { environment } from 'src/environments/environment';
import { HttpHeaderInterceptor } from './interceptors';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MultiLoadingIndicatorModule } from './widgets/shared/multi-loading-indicator/multi-loading-indicator.module';
import { Globals } from './globals';

export const httpProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LayoutModule,
    WidgetsModule,
    BrowserModule,
    LoadingIndicatorModule,
    MultiLoadingIndicatorModule,
    BrowserAnimationsModule,
    // App Modules
    HttpClientModule,
    AppRoutingModule.forRoot(),
    StoreModule.forRoot(
      {
        dashboardDetails: reducers.dashboardDetailsReducer,
        businuessDetails: reducers.businuessDetailsReducer,
        employerBilling: reducers.employerBillingReducer,
        enrollmentInfo: reducers.enrollmentInformationReducer,
        currentBenefits: reducers.currentBenefitsReducer,
        eligiblityGuidelines: reducers.eligibilityGuidelinesReducer,
        proposedProductsInfo: reducers.proposedProductsReducer,
        commissionStructure: reducers.commissionDetailsReducer,
        formsDataDetails: reducers.formsDataDetailsReducer,
        accountIdDetails: reducers.accountIdReducer,
        contactIdDetails: reducers.contactIdReducer,
        selectedCustomerDetails: reducers.customerDetailsReducer,
        tokenDetails: reducers.tokenReducer,
        sicCodeDetails: reducers.sicCodeReducer,
        contactDetails: reducers.contactDetailsReducer,
        fileDetails: reducers.fileIdReducer,
        hrContactDetails: reducers.hrContactDetailsReducer,
        agentNameDetails: reducers.agentNamesReducer,
        CommissionAgentNamesDetails: reducers.commissionAgentNamesReducer,
        brokerNamesDetails: reducers.brokerNamesReducer,
        localChangesDetails: reducers.localChangesReducer,
        proposedProductDataDetails: reducers.proposedProductDataReducer,
        sicRateClassDetails: reducers.sicRateClassReducer,
        productsRestrictions: reducers.productsRestrictionReducer,
        ghiCustomPlanDetails: reducers.ghiCustomPlanDetailsReducer,
      },
      {
        metaReducers: [reducers.clearStore],
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }
    ),
    // Analytics Module for instrumenting events
    AnalyticsModule.forRoot([
      GoogleTagManagerService,
      ApplicationInsightsService,
      GoogleAnalyticsService
    ],
    {
      applicationInsights: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
      },
    }),
    IdlingModule,
    MatDialogModule,
    /** MSAL module config */
    MsalModule.forRoot(
      {
        auth: {
          clientId: environment.msalConfig.clientId,
          authority:
            "https://login.microsoftonline.com/" + environment.msalConfig.tenantId,
          redirectUri: environment.msalConfig.redirectUri,
          postLogoutRedirectUri: environment.msalConfig.postLogoutRedirectUri
        },
        cache: {
          cacheLocation: "localStorage"
        }
      },
      {
        consentScopes: ["user.read", "openid", "profile"]
      }
    )
  ],
  providers: [
    AppContextGuard,
    RouterStateService,
    AppRedirectGuard,
    LoadingIndicatorService,
    CommonDialogService,
    WINDOW_PROVIDERS,
    // For lazy loading modules
    { provide: CRUX_APP_BUILD, useValue: { platform: 'DESKTOP' } },
    MsalService,
    MsalGuard,
    BroadcastService,
    MsalAuthGuard,
    BrowserBackGuard,
    httpProviders,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    Globals
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoadingIndicatorComponent]

})
export class AppModule { }
